import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './app/app';

import '@shopify/polaris/build/esm/styles.css';
import './styles.css';

import './auth';

const root =
  ReactDOM.createRoot(
    document.getElementById(
      'root'
    ) as HTMLElement
  );
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);


// Expected env variables
// https://vitejs.dev/guide/env-and-mode.html
declare global {
    interface ImportMeta {
        env: {
            // standard
            BASE_URL: string;
            DEV: boolean;
            MODE: 'development'|'production';
            PROD: boolean;
            SSR: boolean;
            // custom (defined in .env)
            VITE_APP_GQL_API_URL?: string;
            VITE_ADMIN_BASE_PATH?: string;
            VITE_SHOPIFY_CLIENT_ID?: string;
        }
    }
}