import React, { useEffect, useState } from 'react';
import {
    Banner,
    Text,
    AlphaCard,
    AlphaStack,
    Box,
    Columns,
    TextField,
    Page,
    Divider,
    Loading,
    ContextualSaveBar,
    SkeletonPage,
    SkeletonDisplayText,
    Button,
    Inline,
    Link,
    Image,
    EmptyState,
    Layout
} from '@shopify/polaris';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    EnvironmentSettingsInfo,
    EnvironmentSettingsInput,
    GetSettingsQuery,
    UpdateSettingsMutation
} from '../../../generated/gql/graphql';

import testImg from './test.png';
import { ProviderSettings } from './providers';
import { ErrorPage } from '../../app/errors';
import { CountrySettings } from './countries';
import { SegmentSettings } from './segments';

export const GET_SETTINGS = gql`
    query GetSettings {
        settings {
            liveSettings {
                truelayerKeyId
                truelayerClientId
                webhookUrl
                kmsSigningKeyId
                pubKey
                truelayerClientSecretPresent
                providerCountryFilter
                providerCustomerSegmentFilter
                providerDenylistFilter
            }
            testSettings {
                truelayerKeyId
                truelayerClientId
                webhookUrl
                kmsSigningKeyId
                pubKey
                truelayerClientSecretPresent
                providerCountryFilter
                providerCustomerSegmentFilter
                providerDenylistFilter
            }
        }
    }
`;

export const UPDATE_SETTINGS = gql`
mutation UpdateSettings($input: SettingsUpdateInput!) {
    settingsUpdate(input: $input) {
        liveSettings {
            truelayerKeyId
            truelayerClientId
            truelayerClientSecret
            webhookUrl
            kmsSigningKeyId
            pubKey
            truelayerClientSecretPresent
            providerCountryFilter
            providerCustomerSegmentFilter
            providerDenylistFilter
        }
        testSettings {
            truelayerKeyId
            truelayerClientId
            truelayerClientSecret
            webhookUrl
            kmsSigningKeyId
            pubKey
            truelayerClientSecretPresent
            providerCountryFilter
            providerCustomerSegmentFilter
            providerDenylistFilter
        }
    }
}
`;

export interface SettingsProps {
    test?: boolean;
}

interface InputErrors {
    [key: string]: string;
}

const VALIDATION_ERRORS = {
    required: 'This field is required'
};

const PROVIDER_SETTINGS_CONFIGURABLE = false; // Providers to be reintroduced at a later date

export const Settings: React.FC<SettingsProps> = (props) => {
    const { test } = props;

    const [settings, setSettings] = useState<EnvironmentSettingsInput & {
        webhookUrl?: string,
        pubKey?: string|null|undefined,
        truelayerClientSecretPresent?: boolean|null|undefined
    }>({
        truelayerKeyId: null,
        truelayerClientId: null,
        truelayerClientSecret: null,
        providerCountryFilter: [],
        providerDenylistFilter: [],
        providerCustomerSegmentFilter: []
    });

    const [inputErrors, setInputErrors] = useState<InputErrors>({});
    const [accountConnectionStep, setAccountConnectionStep] = useState<number>(1);
    const [resetTriggered, setResetTriggered] = useState<boolean>(false);

    const [dirty, setDirty] = useState(false);

    const {
        loading: loadingInitial,
        data: dataInitial,
        error: errorInitial,
        refetch
    } = useQuery<GetSettingsQuery>(GET_SETTINGS, {
        notifyOnNetworkStatusChange: true,
        onCompleted: async ({settings}) => {
            setSettings(test ? settings.testSettings : settings.liveSettings);
        }
    });

    const [mutateUpdate, {
        loading: loadingUpdate,
        error: loadingError
    }] = useMutation<UpdateSettingsMutation>(UPDATE_SETTINGS, {
        variables: {
            input: (test ? {
                testSettings: Object.fromEntries(Object.entries(settings).filter(([k, v]) => {
                    return k != '__typename' && k != 'webhookUrl' && k != 'pubKey' && k != 'kmsSigningKeyId' && k != 'truelayerClientSecretPresent';
                })),
            } : ({
                liveSettings: Object.fromEntries(Object.entries(settings).filter(([k, v]) => {
                    return k != '__typename' && k != 'webhookUrl' && k != 'pubKey' && k != 'kmsSigningKeyId' && k != 'truelayerClientSecretPresent';
                })),
            }))
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: ({settingsUpdate}) => {
            setSettings(test ? settingsUpdate.testSettings : settingsUpdate.liveSettings);
            setDirty(false);
        }
    });

    const resetCredentials = () => {
        setSettings({
            ...settings,
            truelayerKeyId: null,
            truelayerClientId: null,
            truelayerClientSecret: null,
        });

        setResetTriggered(true);
    }

    useEffect(() => {
        if (resetTriggered) {
            handleSave();
        }

        setResetTriggered(false);
    }, [resetTriggered]);

    const loading = loadingInitial || loadingUpdate;
    if (loadingInitial) {
        return (
            <SkeletonPage title='Settings'>
                <Loading/>
                <SkeletonDisplayText/>
            </SkeletonPage>
        );
    }

    if (errorInitial) {
        return (
            <ErrorPage error={errorInitial}/>
        );
    }

    const handleSave = () => {
        void mutateUpdate();
    };
    const handleDiscard = () => {
        void refetch();
        setDirty(false);
    };

    const handleChange = <T, K extends keyof T>(state: T, setFn: (v: T) => unknown, key: K) => {
        return (v: T[K]) => {
            setFn({...state, [key]: v});
            setDirty(true);
        };
    };

    const handleSettingsUpdate = (settings: EnvironmentSettingsInput) => {
        setSettings(settings);
        setDirty(true);
    };

    const downloadSigningPubKey = () => {
        const element = document.createElement("a");
        const file = new Blob([settings.pubKey!], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "tl-signing-key-pub.pem";
        document.body.appendChild(element);
        element.click();
    };

    const saveClientCreds = (step: number = 1) => {
        let errors: InputErrors = {};
        setInputErrors(errors);

        if (step === 1) {
            if (!settings.truelayerClientId?.trim()) {
                errors['truelayerClientId'] = VALIDATION_ERRORS.required;
            }
            if (!settings.truelayerClientSecret?.trim()) {
                errors['truelayerClientSecret'] = VALIDATION_ERRORS.required;
            }
        } else if (step === 2) {
            if (!settings.truelayerKeyId?.trim()) {
                errors['truelayerKeyId'] = VALIDATION_ERRORS.required;
            }
        }

        if (Object.keys(errors).length) {
            setInputErrors(errors);
            return;
        }

        setAccountConnectionStep(accountConnectionStep + 1);

        handleSave();
    };

    const envLabel = test ? 'Sandbox' : 'Live';

    const credentialsComplete = !!settings.truelayerClientId && settings.truelayerClientSecretPresent && settings.truelayerKeyId;

    return (
        <Page
            title="Settings"
            divider
        >
            {loading ? (
                <Loading/>
            ) : undefined}
            {dirty ? (
                <ContextualSaveBar
                    message="Unsaved changes"
                    saveAction={{
                        onAction: handleSave,
                        loading: false,
                        disabled: false,
                    }}
                    discardAction={{
                        onAction: handleDiscard,
                    }}
                />
            ) : undefined}
            <Layout>
                <Layout.AnnotatedSection
                    title={`${envLabel} account credentials`}
                    description='These credentials will be used to connect to your Truelayer account.'
                >
                    <AlphaCard padding="0">
                        <div style={!credentialsComplete || dirty  ? {} : {pointerEvents: 'none', opacity: 0.5} }>
                            <Box padding="4">
                                <AlphaStack gap="4" fullWidth>
                                    <TextField
                                        label={`${envLabel} Client ID`}
                                        autoComplete='off'
                                        readOnly={loading}
                                        value={settings.truelayerClientId || ''}
                                        error={inputErrors.truelayerClientId}
                                        onChange={handleChange(settings, setSettings, 'truelayerClientId')}
                                    />
                                    <TextField
                                        label={`${envLabel} Client Secret`}
                                        autoComplete='off'
                                        readOnly={loading}
                                        value={(!dirty && (settings.truelayerClientSecretPresent)) ? '*******' :  (settings.truelayerClientSecret || '')}
                                        error={inputErrors.truelayerClientSecret}
                                        onChange={handleChange(settings, setSettings, 'truelayerClientSecret')}
                                    />
                                </AlphaStack>
                            </Box>
                        </div>
                        {settings.kmsSigningKeyId && (
                            <div>
                                <Divider/>
                                <Box padding="4">
                                    <div style={!credentialsComplete || dirty ? {} : {pointerEvents: 'none', opacity: 0.5}}>
                                        <AlphaStack gap="4" fullWidth>
                                            <Inline align='space-between' blockAlign='center'>
                                                <Text variant='bodyMd' as='span'>
                                                    <Text variant='headingSm' as='p'>
                                                        Signing key
                                                    </Text>
                                                    <p>
                                                        Download your signing key and upload it to your
                                                        {' '}
                                                        <Link url='https://console.truelayer.com/payments/v3/settings'>
                                                            Truelayer console
                                                        </Link>
                                                        {' '}
                                                        in
                                                        {' '}
                                                        <strong>
                                                            {envLabel}
                                                            {' '}
                                                            mode
                                                        </strong>
                                                        .
                                                    </p>
                                                </Text>
                                                <Button onClick={downloadSigningPubKey}>
                                                    Download key
                                                </Button>
                                            </Inline>
                                            <TextField
                                                label={`${envLabel} KID`}
                                                autoComplete='off'
                                                placeholder='Copy KID here after uploading signing key'
                                                readOnly={loading}
                                                value={settings.truelayerKeyId || ''}
                                                error={inputErrors.truelayerKeyId}
                                                onChange={handleChange(settings, setSettings, 'truelayerKeyId')}
                                            />
                                        </AlphaStack>
                                    </div>

                                    {credentialsComplete && !dirty && (
                                        <Box paddingBlockStart="4" paddingBlockEnd="2" paddingInlineStart="0">
                                            <Banner action={{
                                                content: 'Reset credentials',
                                                onAction: () => resetCredentials()
                                            }}>
                                                <p>
                                                    Lost the signing key or need to edit credentials? Reset your credentials
                                                    first.
                                                </p>
                                            </Banner>
                                        </Box>
                                    )}
                                </Box>
                            </div>
                        )}
                        {(!credentialsComplete || dirty) && (
                            <Box paddingBlockStart="0" paddingBlockEnd="4" paddingInlineStart="4">
                                <AlphaStack align="start">
                                    <Button primary onClick={() => saveClientCreds(accountConnectionStep)}
                                            loading={loading}>Save & Continue</Button>
                                </AlphaStack>
                            </Box>
                        )}

                        <Divider/>
                        <Box padding="4">
                            <AlphaStack gap="4" fullWidth>
                                <Inline align='space-between' blockAlign='center'>
                                    <Text variant='bodyMd' as='span'>
                                        <Text variant='headingSm' as='p'>
                                            Webhook URL
                                        </Text>
                                        <p>
                                            To ensure all payments are handled correctly, add the following webhook URL
                                            in your
                                            {' '}
                                            <Link url='https://console.truelayer.com/payments/v3/settings'>
                                                TrueLayer console
                                            </Link>
                                            .
                                        </p>
                                    </Text>
                                </Inline>
                                <TextField
                                    label="Webhook URL"
                                    readonly
                                    labelHidden
                                    autoComplete='off'
                                    readOnly={loading}
                                    value={settings.webhookUrl}
                                />
                            </AlphaStack>
                        </Box>
                    </AlphaCard>
                </Layout.AnnotatedSection>

                {PROVIDER_SETTINGS_CONFIGURABLE && (
                    <Layout.AnnotatedSection
                        title={`${envLabel} provider selection`}
                        description='Control which providers (i.e. banks) the customer can use to pay.'
                    >
                        <AlphaCard padding="0">
                            <AlphaStack align="start" fullWidth>
                                <Box padding="4">
                                    <SegmentSettings settings={settings} onChange={handleSettingsUpdate}/>
                                </Box>
                                <Divider/>
                                <Box padding="4">
                                    <CountrySettings settings={settings} onChange={handleSettingsUpdate}/>
                                </Box>
                                <Divider/>
                                <Box padding="4">
                                    <ProviderSettings test={test} settings={settings} onChange={handleSettingsUpdate}/>
                                </Box>
                            </AlphaStack>
                        </AlphaCard>
                    </Layout.AnnotatedSection>
                )}
            </Layout>
        </Page>
    );
};
