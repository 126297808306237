import { EmptyState, Page } from "@shopify/polaris";
import React from "react";
import { ErrorPage } from "../../app/errors";

export const Unauthed: React.FC = () => {
    return (
        <Page>
            <EmptyState
                heading="Session expired" 
                image=""            
            >
                <p>
                    Please open the app again from your Shopify Admin.
                </p>
            </EmptyState>
        </Page>
    )
};