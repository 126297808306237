// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './app.module.css';

import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Page, LegacyCard, Button, Frame, Navigation, TopBar } from '@shopify/polaris';
import {
    ArrowLeftMinor,
} from '@shopify/polaris-icons';
import { createBrowserRouter, RouterProvider, useLocation, useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import React from 'react';
import { Dashboard } from '../routes/dashboard';
import { Settings } from '../routes/settings';
import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { attemptReauth, getToken } from '../auth';
import { ContextualSaveBar } from '@shopify/polaris/build/ts/latest/src/components/Frame/components';
import { LinkLikeComponent, LinkLikeComponentProps } from '@shopify/polaris/build/ts/latest/src/utilities/link';
import { AppFrame } from './frame';
import { Unauthed } from '../routes/unauthed';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        const authError = graphQLErrors.filter((e) => e.message == '401')[0];
        if (authError) {
            attemptReauth();
        }

        graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
    }
    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: Record<string, string> }) => ({
        headers: {
            ...headers,
            'Authorization': `Bearer ${getToken()}`
        }
    }));
    return forward(operation);
});

const gqlUrl = import.meta.env.VITE_APP_GQL_API_URL || `fallback`;

const link = from([
    errorLink,
    authLink,
    new HttpLink({ uri: gqlUrl })
]);

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
});

export const App: React.FC = () => {
    const router = createBrowserRouter([
        {
            element: <AppFrame/>,
            children: [
                {
                    path: '/',
                    element: <Dashboard/>
                },
                {
                    path: '/settings/live',
                    element: <Settings key="live-settings"/>
                },
                {
                    path: '/settings/test',
                    element: <Settings test key="test-settings"/>
                },
            ]
        },
        {
            path: '/401',
            element: <Unauthed/>
        },
    ], {
        basename: import.meta.env.VITE_ADMIN_BASE_PATH || '/app'
    });

    return (
        <ApolloProvider client={client}>
            <AppProvider
                i18n={enTranslations}
                linkComponent={Link}
            >
                <RouterProvider router={router}/>
            </AppProvider>
        </ApolloProvider>
  );
}



const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
const Link = ({children, url = '', external, ref, ...rest} : LinkLikeComponentProps) =>{
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = '_blank';
    rest.rel = 'noopener noreferrer';
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

export default App;
