const TOKEN_KEY = '_token';

let token = null;
export const loadToken = () => {
    token = (new URLSearchParams(window.location.hash.substring(1))).get('token');
    if (token) {
        sessionStorage.setItem(TOKEN_KEY, token);
        window.location.hash = '';
    }
};

loadToken();

export const getToken = (): string|null => {
    return sessionStorage.getItem(TOKEN_KEY)!;
};

export const attemptReauth = () => {
    // If our auth token expires, attempt to redirect back to the shop's oauth, otherwise give an error page.
    const token = getToken();
    const shop = token ?  determineShopFromToken(token) : null;
    if (!shop) {
        window.location.href = '/app/401';
        return;
    }
    window.location.href = `https://${shop}/admin/apps/${import.meta.env.VITE_SHOPIFY_CLIENT_ID}/oauth/start`;
    return;
};


export const determineShopFromToken = (token: string): string|null => {
    try {
        // Decode without validation, as this is used to try to find
        // a potential re-auth redirect when the token is invalid.
        const payload = token.split('.')[1];
        const data = JSON.parse(atob(payload));
        return data.shopDomain;
    } catch (e) {
        return null;
    }
};