import { determineShopFromToken, getToken } from "../auth";

export const handleReturnToShopify = () => {
    const token = getToken();
    const shop = token ? determineShopFromToken(token) : null;
    if (!shop) {
        window.location.href = 'https://shopify.com';
        return;
    }
    window.location.href = `https://${shop}/services/payments_partners/gateways/${import.meta.env.VITE_SHOPIFY_CLIENT_ID}/settings`;
};